import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';
import service from './service';
import LoadingContext from '../../../container/loadingContext';
import i18n from '../../../utilities/i18n';
import { getStorage } from '../../../utilities/browserStorage';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_REFERRING_PROVIDER_ADD, ACTIVE_TYPES, ROUTE_REFERRING_PROVIDER_EDIT } from '../../../utilities/staticConfigs';
import Pagination from '../../commons/pagination';
import SelectInput from '../../commons/input/SelectInput';
import Table from '../../commons/Table/Table';
import { ReferingProviderTableData, TableBodyData as ReferringProviderTableBodyData } from './ReferingProviderTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import ExportIconButton from '../../commons/exportIconButton';
import Notify from '../../commons/notify';
import { permission_key_values_accountsetup } from '../../../utilities/permissions';

const ReferringProviderList = () => {
    const history = useHistory();
    const practicePK = getStorage("practice");
    const [referringProviderList, setReferringProviderList] = useState([]);


    //start ==== Alert message properties
    const setShowLoadingBar = useContext(LoadingContext);
    //End ====

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
    const [activeType, setActiveType] = useState('2');
    const [showNotify, setShowNotify] = useState("hide");
    const [notifyDescription, setNotifyDescription] = useState("");
    const [notifyType, setNotifyType] = useState("success");
    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);
        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getReferringProvidersList(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getReferringProvidersList(DEFAULT_PAGING_SIZE, page);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);
        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);
        }
        getReferringProvidersList(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Pagination ends

    useEffect(() => {
        getReferringProvidersList(DEFAULT_PAGING_SIZE, 1);
    }, [activeType]);

    function getReferringProvidersList(pageSize, page) {
        setShowLoadingBar(true);
        let status = activeType == 1 ? 'all' : activeType == '2' ? 'active' : 'inactive';
        const result = service.ListReferringProviders(pageSize, page, practicePK, status);
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data && response?.data?.results?.length) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
                const rowArray = commonTableBody(response.data.results, ReferingProviderTableData.tableBodyData[0])
                ReferingProviderTableData.tableBodyData = rowArray;
                setReferringProviderList(response.data.results);
            } else {
                setTotalPage(0);
                ReferingProviderTableData.tableBodyData = ReferringProviderTableBodyData;
                setReferringProviderList([]);
                setActivePage(1)
            }
        });
    }

    function onEditReferringProvider(referringProviderID) {
        history.push(ROUTE_REFERRING_PROVIDER_EDIT, { referringProviderID: referringProviderID })
    }
    function addNewReferringProvider() {
        history.push(ROUTE_REFERRING_PROVIDER_ADD)
    }
    function onHandleChange(e) {
        setActiveType(e.target.value);
    }

    function dropDownFunction(id, name) {
        if (name === 'Edit') {
            if(checkPermission(permission_key_values_accountsetup.account_setup_referring_provider_modify)){
                onEditReferringProvider(id);
            } else {
                showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            }
        }
    }
    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === "show") {
            setTimeout(() => {
                setShowNotify("hide");
            }, age);
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);
    }
    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        let status = activeType === '1' ? 'all' : activeType === '2' ? 'active' : 'inactive';
        service.exportReferingProvider(type, practicePK, status)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else {
                    if (result.status === 404 && result.message === 'no_data_available_for_export') {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
                        setShowLoadingBar(false);
                    }
                    else {
                        showNotifyWindow('show', 'error', result.statusText ? result.statusText : 'Unexpected Error');
                        setShowLoadingBar(false);
                    }
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };


    return (
        <React.Fragment>
            <Notify
                showNotify={showNotify}
                setShowNotify={setShowNotify}
                notifyDescription={notifyDescription}
                setNotifyType={setNotifyType}
                setNotifyDescription={setNotifyDescription}
                notifyType={notifyType}
            />
            <div className="col-md-12">
                <div className="box min-h-full margin-right15">
                {((referringProviderList && referringProviderList.length > 0) || activeType !== "1") && (
                 <div className='box-content-white'>
                    <div className="row">
                        <div className="col"></div>
                        <div className="mr-1 justify-right">
                            <SelectInput data={ACTIVE_TYPES} name="ActiveType" id="ActiveType" value={activeType} onValueChange={onHandleChange} label={i18n.t('commons.status')} selectOptionRemove={true} labelClassName={"margin-bottom0"} />
                        </div>
                        <div className="justify-right padding-right5 addNew-btn">
                            <div className="mr-4 margin-top1">
                                <ExportIconButton disabled ={!referringProviderList?.length} onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')} onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                            </div>
                            {/* permission checking */}
                            {checkPermission(permission_key_values_accountsetup.account_setup_referring_provider_add) &&
                            <button type="button" className="btn btn-primary-blue mr-3 btn-common-height35" onClick={addNewReferringProvider}>
                                {i18n.t("buttons.addNew")}
                            </button>}
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="table-responsive">
                            <Table tableObject={ReferingProviderTableData} dropDownFunction={dropDownFunction} />
                        </div>
                        <div className='padding-top10'></div>
                        <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                    </div>
                </div>
                 )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReferringProviderList