import React, { useContext, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Redirect, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import "./style.css";
import service from "./service";
import SearchClaim1 from "./SearchClaim1";
import ProfessionalClaims from "./ProfessionalClaims";
import AddIcon from '@mui/icons-material/Add';

import LoadingContext from "../../../container/loadingContext";

import patientService from "../../patientManagement/patients/service";

import Notify from "../../commons/notify";
import { csvxlsFileCheck } from "../../../utilities/validations";

import i18n from "../../../utilities/i18n";
import { getStorage } from "../../../utilities/browserStorage";
import {
  checkPermission,
  commonTableBody,
} from "../../../utilities/commonUtilities";
import {
  ROUTE_PATIENTS_LIST,
  MAX_OPEN_TABS_CLAIMS,
  MAX_OPEN_TABS_PATIENTS,
  ROUTE_INSURANCE_COMPANIES_EDIT,
} from "../../../utilities/staticConfigs";

import Table from "../../commons/Table/Table";
import { SearchClaimTableData } from "./SearchClaimTable";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, IconButton, Tab } from "@mui/material";
import CustomizedSmallDialogs from "../../modalWindowComponent/CustomisedSmallDialog";
import { permission_key_values_claim, permission_key_values_patient } from "../../../utilities/permissions";
import ExportIconButton from "../../commons/exportIconButton";
import { useSelector, useDispatch } from "react-redux";
import { removeClaimInformations, updateProcedureData } from "../claimManagementSlice/claimManagementSlice";
import { removeAllDetails, updateProcedureSaveData } from "../claimManagementSlice/saveManagementSlice";
import { styled } from '@mui/material/styles';
import CommonButton from "../../commons/Buttons";
import { removeResponsibility } from "../claimManagementSlice/claimSelectInputSlice";
// Custom style for the indicator
const CustomIndicator = styled('span')(() => ({
  // Define your custom indicator styles here
  backgroundColor: 'white',
  height: 7,
  position: 'absolute',
  bottom: 0,
  width: 'calc(100% - 4px)',
  marginLeft: '2px'

}));

const StyledTabList = styled(TabList)`
& .MuiTabs-indicator {
  background-color: transparent !important;
  height: 0px !important;
}
`;
function SearchClaims(props) {
  const cliamDetails = useSelector(state => state.claimData.commonData);
  const Mode_Of_Save = useSelector(state => state.claimSaveManagmentData.saveMode);
  const dispatch = useDispatch();
  const history = useHistory();
  const setShowLoadingBar = useContext(LoadingContext);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [activeTab, setActiveTab] = useState("listLast10Claims");
  const [activeRowId, setActiveRowId] = useState(0);
  const [claimList, setClaimList] = useState([]);
  const [selectedClaimType, setSelectedClaimType] = useState("");
  const [redirectToPatient, setRedirectToPatient] = useState(false);
  const [claimSearchQuery, setClaimSearchQuery] = useState("");
  const [ProcedureRows, setProcedureRows] = useState([])
  const [showAlertModalWindow, setShowAlertModalWindow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openedTabPKs, setOpenedTabPKs] = useState([]);
  const [initialTableData, setInitialTableData] = useState(
    i18n.t("commons.loading")
  );
  const newClaimAction = new Date(); // USED TO CLEAR "Provider Info" IN "BillingInfo.js"
  const [selectedID, setSelectedID] = useState("0");

  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  // import consts
  const [importErrorMessage, setImportErrorMessage] = useState("");
  const [showImportErrorModalWindow, setShowImportErrorModalWindow] =
    useState(false);
  const [validateData, setValidateData] = useState([]);

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  const [tabsOpen, setTabsOpen] = useState(0);
  // table column sorting
  const [orderType, setOrderType] = useState("");
  const [initialOrderingPatientName, setInitialOrderingPatientName] =
    useState(true);
  const [initialOrderingClaimId, setInitialOrderingClaimId] = useState(true);
  const [initialOrderingDos, setInitialOrderingDos] = useState(true);
  const [initialOrderingBillDate, setInitialOrderingBillDate] = useState(true);
  SearchClaimTableData.tableHeadings[0].initialOrdering =
    initialOrderingClaimId;
  SearchClaimTableData.tableHeadings[0].orderType = orderType;
  SearchClaimTableData.tableHeadings[1].initialOrdering =
    initialOrderingPatientName;
  SearchClaimTableData.tableHeadings[1].orderType = orderType;
  SearchClaimTableData.tableHeadings[2].initialOrdering = initialOrderingDos;
  SearchClaimTableData.tableHeadings[2].orderType = orderType;
  SearchClaimTableData.tableHeadings[5].initialOrdering =
    initialOrderingBillDate;
  SearchClaimTableData.tableHeadings[5].orderType = orderType;



  useEffect(() => {
    if (history.location.state?.selected) {
      let pk = Number(history.location.state.selected);
      let cust_id = Number(history.location.state.id);
      let item = {
        pk: pk,
        claim_id: cust_id,
        type: "claims",
        action: "add",
        practice_pk: getStorage("practice"),
      };
      addRemoveFromSelectedTab(item);
    }
  }, []);

  useEffect(
    () => {
      let arrPTabs = [];
      if (openedTabPKs && Array.isArray(openedTabPKs)) {
        arrPTabs = openedTabPKs.filter(
          (item) => parseInt(item, 10) > 0 || item == "add_new"
        );
        setTabsOpen(arrPTabs ? arrPTabs.length : 0);
      } else {
        setTabsOpen(0);
      }
    },
    [openedTabPKs]
  );

  function onTabChange(event, tab, id, claimId) {
    if (event.target) {
      let name = event?.target?.name;
      if (name === "search") {
        let item = {
          pk: name,
          claim_id: name,
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        addRemoveFromSelectedTab(item);
        setActiveTab(tab);
      } else if (name === "last10") {
        let item1 = {
          pk: name,
          claim_id: name,
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        addRemoveFromSelectedTab(item1);
        setActiveTab(tab);
      } else if (name === "add_new") {
        let item2 = {
          pk: name,
          claim_id: name,
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        addRemoveFromSelectedTab(item2);
        setActiveTab(tab);
      } else if (name) {
        let item3 = {
          pk: Number(name.split(" ")[1]),
          claim_id: Number(name.split(" ")[2]),
          type: "claims",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        addRemoveFromSelectedTab(item3);
        setActiveTab(tab);
      }
    } else {
      let item4 = {
        pk: id,
        claim_id: claimId,
        type: "claims",
        action: "add",
        practice_pk: getStorage("practice"),
      };
      addRemoveFromSelectedTab(item4);
      setActiveTab(tab);
    }
    //procedure row is dispatched when above tab is clicked correctly
    if (ProcedureRows.length > 0) {
      if (Mode_Of_Save !== 'Save') {
        dispatch(updateProcedureData({ procedureArray: ProcedureRows, pk: cliamDetails?.pks?.diagnosises_pk }));
      }
      else {
        dispatch(updateProcedureSaveData({ procedureArray: ProcedureRows }))
      }
    } 
  }

  function onShowActionButtonsClaim(event, rowId) {
    let dropdown = document.getElementById("dropdown-" + rowId);
    let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
    dropdown.classList.toggle("show");
    dropdownMenu.classList.toggle("show");
    if (activeRowId === 0) {
      setActiveRowId(rowId);
    } else if (activeRowId === rowId) {
      setActiveRowId(0);
    } else if (this.state.activeRowId !== rowId) {
      dropdown = document.getElementById("dropdown-" + activeRowId);
      dropdownMenu = document.getElementById("dropdown-menu-" + activeRowId);
      dropdown.classList.toggle("show");
      dropdownMenu.classList.toggle("show");
      setActiveRowId(rowId);
    }
  }

  function onHideActionButtonsClaim(event, rowId) {
    let dropdown = document.getElementById("dropdown-" + rowId);
    let dropdownMenu = document.getElementById("dropdown-menu-" + rowId);
    dropdown.classList.remove("show");
    dropdownMenu.classList.remove("show");
    setActiveRowId(0);
  }

  async function addRemoveFromSelectedTab(item) {
    try {
      const response = await service.AddRemoveSelectedTab(item);

      if (response?.data) {
        if (response.data?.code === 404) {
          showNotifyWindow("show", "error", i18n.t("errorMessages.not_found"));
        } else {
          if (response.data?.code === 409) {
            showNotifyWindow("show", "error", i18n.t("errorMessages.max_claim_tabs"));
          }
          if (item.action === 'remove' && item.pk !== "add_new") {
            dispatch(removeClaimInformations(item.pk))
            dispatch(removeResponsibility())
          }
          if (item.action === 'remove' && item.pk === "add_new") {
            dispatch(removeAllDetails(1))
          }
          await getSelectedClaims();
        }
      }
    } catch (error) {
      console.error('Error in addRemoveFromSelectedTab:', error);
    }
  }

  async function getSelectedClaims() {
    try {
      const response = await service.GetSelectedTabs("claims", getStorage("practice"));
      if (response?.data?.data) {
        setSelectedClaims(response.data.data);
      }
      if (response?.data?.opened_tab_pks) {
        setOpenedTabPKs(response.data.opened_tab_pks);
      }
      if (activeTab === "listClaims") {
        const filteredClaims = response?.data?.data?.filter((obj) => {
          return obj.pk === "search";
        });
        if (filteredClaims.length > 0) {
          setClaimSearchQuery(filteredClaims[0].query);
        }
      }
      if (response?.data?.code === 204 && response?.data?.message === "No Content") {
        setOpenedTabPKs(["last10"]);
        setSelectedClaims([{ "pk": "last10", "is_opend": true }])
      }
      return
    } catch (error) {
      console.error("Error while fetching selected claims:", error);
    }
  }

  function onEditClaim(pk, name, claimId, patientID) {
    let claimOpened = false;
    if (openedTabPKs && Array.isArray(openedTabPKs)) {
      claimOpened = openedTabPKs.includes(patientID);
    }

    if (!claimOpened && tabsOpen >= MAX_OPEN_TABS_CLAIMS) {
      showNotifyWindow("show", "error", i18n.t("errorMessages.max_claim_tabs"));
    } else {
      if (openedTabPKs && openedTabPKs.includes(patientID)) onAlertOk();
      else CheckForPatientAlert(patientID);
      let item = {
        pk: pk,
        claim_id: claimId,
        type: "claims",
        action: "add",
        practice_pk: getStorage("practice"),
      };
      addRemoveFromSelectedTab(item);
    }
  }

  function CheckForPatientAlert(patientPK) {
    setShowLoadingBar(true);
    let data = {
      patient_pk: patientPK,
      alert_type: 1, // Alert type of Patient
    };
    const result = patientService.CheckForAlert(data);
    result.then((response) => {
      if (response.data.has_alert) {
        setShowAlertModalWindow(true);
        setAlertMessage(response.data.alert_message);
      }
      setShowLoadingBar(false);
    });
  }

  function closeClaimTab(e, item) {
    item["action"] = "remove";
    item["type"] = "claims";
    item["practice_pk"] = getStorage("practice");
    addRemoveFromSelectedTab(item);
  }

  async function getListClaims(sorting, fieldName) {
    setShowLoadingBar(true);
    
    let query = "?list=true&last10=true&practice_pk=" + getStorage("practice") + "&page=0";
    
    if (sorting) {
      query += "&needPageZeroPagination=1&column=" + orderType + fieldName;
    }

    try {
        const response = await service.GetListPatientClaims(query);
        if (response?.data) {
            const rowArray = commonTableBody(response.data, SearchClaimTableData.tableBodyData[0]);
            SearchClaimTableData.tableBodyData = rowArray;
            setClaimList(response.data);
        }
    } catch (error) {
        console.error("Error while fetching claims list:", error);
    } finally {
        setShowLoadingBar(false);
    }
}

  function sortingFunction(e, name) {
    name = name.replace(" ", "_").toLowerCase();
    let fieldName = "";
    if (name == "patient_name") {
      fieldName = "patient__full_name";
      setInitialOrderingPatientName(false);
      setInitialOrderingClaimId(true);
      setInitialOrderingDos(true);
      setInitialOrderingBillDate(true);
    } else if (name == "claim_id") {
      fieldName = "custom_claim_id";
      setInitialOrderingClaimId(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingDos(true);
      setInitialOrderingBillDate(true);
    } else if (name == "dos") {
      fieldName = "service_from_date";
      setInitialOrderingDos(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingClaimId(true);
      setInitialOrderingBillDate(true);
    } else if (name == "billed_date") {
      fieldName = "last_billed_date";
      setInitialOrderingBillDate(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingClaimId(true);
      setInitialOrderingDos(true);
    }

    if (!orderType) setOrderType("-");
    else setOrderType("");
    if (fieldName) getListClaims(true, fieldName);
  }

  useEffect(() => {
    if (selectedClaims && selectedClaims.length > 0) {
      let openedTab = selectedClaims.find((element) => {
        return element.is_opend;
      });
      //New Profession claim retaining
      let isProfessional = selectedClaims.find((element) => {
        return element.pk === "add_new";
      });
      if (isProfessional) {
        setSelectedClaimType("professional"); // pass the claim type to this function from props.
      } else {
        setSelectedClaimType("");
      }
      if (openedTab.pk === "search") {
        var result = selectedClaims.filter((obj) => {
          return obj.pk === "search";
        });
        setClaimSearchQuery(result[0].query);
        setActiveTab("listClaims");
      } else if (openedTab.pk === "last10") {
        setActiveTab("listLast10Claims");
        getListClaims();
      } else if (openedTab.pk === "add_new") setActiveTab("editClaims");
      else setActiveTab("editClaims" + openedTab.pk);
    } else if (!selectedClaims) {
      let openedTabPKs = ["last10"];
      setSelectedClaims([{ pk: "last10", is_opend: true }]);
      setActiveTab("listLast10Claims");
      setOpenedTabPKs(openedTabPKs);
    }

    // IF THIS PAGE IS CALLED FROM USER LOGS, LOAD THE CLAIM ID AS THE ACTIVE ONE
    if (
      props.location.state &&
      props.location.state.selectedID &&
      props.location.state.selectedID !== 0 &&
      selectedID === "0"
    ) {
      setSelectedID(props.location.state.selectedID);
    }
  }, [selectedClaims]);

  useEffect(() => {
    if (selectedID && selectedID.length && selectedID !== "0") {
      let arrTemp = selectedID.split(":::");
      let item = {
        pk: parseInt(arrTemp[0], 10),
        claim_id: parseInt(arrTemp[1], 10),
        type: "claims",
        action: "add",
        practice_pk: getStorage("practice"),
      };
      addRemoveFromSelectedTab(item);
    }
  }, [selectedID]);

  function onPatientNameClick(patientID) {
    const result = service.GetSelectedTabs("patients", getStorage("practice"));
    result.then((response) => {
      let openedPKs =
        response.data && response.data.opened_tab_pks
          ? response.data.opened_tab_pks.filter(
            (item) => parseInt(item, 10) > 0
          )
          : [];
      if (
        openedPKs &&
        Array.isArray(openedPKs) &&
        openedPKs.length >= MAX_OPEN_TABS_PATIENTS &&
        !openedPKs.includes(patientID)
      ) {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.max_patient_tabs")
        );
      } else {
        let item = {
          pk: patientID,
          type: "patients",
          action: "add",
          practice_pk: getStorage("practice"),
        };
        addRemoveFromSelectedTab(item);
        setRedirectToPatient(true);
      }
    });
  }

  useEffect(() => {
    if (!Number(history.location?.state?.selectedID)) getSelectedClaims();
    getListClaims();
  }, []);

  function onAlertOk() {
    setShowAlertModalWindow(false);
  }

  if (redirectToPatient) {
    return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;
  }

  setTimeout(() => {
    setInitialTableData(i18n.t("commons.noRecords"));
  }, 1000);

  var fileDownload = require("js-file-download");

  function onHandleCSVExportClaims() {
    setShowLoadingBar(true);
    let query = "";
    if (activeTab === "listLast10Claims")
      query +=
        "?last10=true&practice_pk=" +
        getStorage("practice") +
        "&page=0&export=true&export_type=csv";
    else if (activeTab === "listClaims")
      if (claimSearchQuery)
        query += claimSearchQuery + "&export=true&export_type=csv";
      else
        query +=
          "?practice_pk=" +
          getStorage("practice") +
          "&page=0&export=true&export_type=csv";
    const result = service.GetListPatientClaims(query);
    result.then((response) => {
      fileDownload(response.data, response.headers["content-disposition"]);
      setShowLoadingBar(false);
    });
  }

  function onHandlePDFExportClaims() {
    setShowLoadingBar(true);
    let query = "";
    if (activeTab === "listLast10Claims")
      query +=
        "?last10=true&practice_pk=" +
        getStorage("practice") +
        "&page=0&export=true&export_type=pdf";
    else if (activeTab === "listClaims")
      if (claimSearchQuery)
        query += claimSearchQuery + "&export=true&export_type=pdf";
      else
        query +=
          "?practice_pk=" +
          getStorage("practice") +
          "&page=0&export=true&export_type=pdf";
    const result = service.GetListPatientClaims(query);
    result.then((response) => {
      fileDownload(response.data, response.headers["content-disposition"]);
      setShowLoadingBar(false);
    });
  }

  function onHandleXLSExportClaims() {
    setShowLoadingBar(true);
    let query = "";
    if (activeTab === "listLast10Claims")
      query +=
        "?last10=true&practice_pk=" +
        getStorage("practice") +
        "&page=0&export=true&export_type=xls";
    else if (activeTab === "listClaims")
      if (claimSearchQuery)
        query += claimSearchQuery + "&export=true&export_type=xls";
      else
        query +=
          "?practice_pk=" +
          getStorage("practice") +
          "&page=0&export=true&export_type=xls";
    const result = service.GetListPatientClaims(query, "xls");
    result.then((response) => {
      fileDownload(response.data, response.headers["content-disposition"]);
      setShowLoadingBar(false);
    });
  }
  const OnExportMenuItemClicked = (e, type) => {
    setShowLoadingBar(true);
    let query = "";
    if (activeTab === "listLast10Claims")
      query +=
        "?last10=true&practice_pk=" +
        getStorage("practice") +
        "&page=0&export=true&export_type=" +
        type;
    else if (activeTab === "listClaims")
      if (claimSearchQuery)
        query += claimSearchQuery + "&export=true&export_type=" + type;
      else
        query +=
          "?practice_pk=" +
          getStorage("practice") +
          "&page=0&export=true&export_type=" +
          type;
    service
      .GetListPatientClaims(query, type)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          fileDownload(result.data, result.headers["content-disposition"]);
          setShowLoadingBar(false);
          showNotifyWindow("show", "success", "Export Success");
        } else if (result.status === 404 && result.message === 'no_data_available_for_export') {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
					setShowLoadingBar(false); 
        } else {
          showNotifyWindow("show", "error", "Unexpected Error");
        }
      })
      .catch(() => {
        showNotifyWindow("show", "error", "Unexpected Error");
        setShowLoadingBar(false);
      });
  };

  function remShowExport() {
    document.getElementById("export-dropdown").classList.remove("show");
    document.getElementById("export-menu").classList.remove("show");
    document.getElementById("export-dropdown").classList.remove("active");
    document.getElementById("export-menu").classList.remove("active");
  }

  function onShowExport() {
    document.getElementById("export-dropdown").classList.toggle("show");
    document.getElementById("export-menu").classList.toggle("show");
  }
  function onHandleCsvImport(e) {
    e.preventDefault();
    document.getElementById("claimfile").value = null;
    document.getElementById("claimfile").click();
  }

  function onHandleCSVChange(e) {
    setShowLoadingBar(true);
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;

    if (e.target.type === "file") {
      if (e.target.files[0] && csvxlsFileCheck(e.target.files[0].name)) {
        const data = new FormData();
        data.append("file", value);
        data.append("practice_pk", getStorage("practice"));
        let result = service.ImportClaim(data);
        result.then((response) => {
          setShowLoadingBar(false);
          if (response.data.message !== undefined) {
            if (response.data.message === "no_file_records") {
              showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.empty_file")
              );
            } else if (response.data.message === "invalid_headings") {
              showNotifyWindow(
                "show",
                "error",
                i18n.t("errorMessages.invalid_headings")
              );
            } else if (response.data.message === "data_imported") {
              if (response.data.row_list) {
                setValidateData(response.data.row_list);
                let validate_data = "";
                for (var key in response.data.row_list) {
                  let mandatory = [];
                  let invalidData = [];
                  response.data.row_list[key]["mandatory"].forEach((e) => {
                    mandatory.push(`&nbsp; ${e}`);
                  });
                  response.data.row_list[key]["invalid_data"].forEach((item) => {
                    invalidData.push(`&nbsp; ${item}`);
                  });

                  validate_data += `<p><u><strong>Row </strong>${key} </u> <br/> <strong>Patient ID :</strong> ${response.data.row_list[key]["patient_id"]}<br/>
                                        <strong>Mandatory :</strong> ${mandatory}<br/>
                                        <strong>Invalid_Data :</strong> ${invalidData} <br/></p>`;
                }
                setImportErrorMessage(
                  `<p style='margin-bottom:10px;'>${response.data.success_count} out of ${response.data.claim_count} patient records have been imported.
                   The records that were not imported are listed below :</p>` +
                  "<br/><br/>" +
                  validate_data
                );
                setShowImportErrorModalWindow(true);
              } else {
                showNotifyWindow(
                  "show",
                  "success",
                  i18n.t("validations.success.import_success")
                );
              }
            }
          } else {
            showNotifyWindow(
              "show", "error",
              response?.data?.error_message ?? i18n.t("errorMessages.import_failed"),
              5000
            );
          }
        });
      } else {
        showNotifyWindow(
          "show",
          "error",
          i18n.t("errorMessages.invalid_file_format")
        );
      }
    }
  }

  function onImportErrorOK() {
    setShowImportErrorModalWindow(false);
  }

  function onDownloadCSV(e) {
    let csvData = ['"ROW","PATIENT ID","MANDATORY","INVALID DATA"'];
    for (var key in validateData) {
      let mandatory = "";
      let invalidData = "";
      for (var k in validateData[key]["mandatory"]) {
        mandatory += validateData[key]["mandatory"][k] + ",";
      }
      for (var i in validateData[key]["invalid_data"]) {
        invalidData += validateData[key]["invalid_data"][i] + ",";
      }
      csvData.push(
        '"' +
        key +
        '",' +
        '"' +
        validateData[key]["patient_id"] +
        '",' +
        '"' +
        mandatory +
        '",' +
        '"' +
        invalidData +
        '"'
      );
    }
    var CSV = csvData.join("\n");
    window.URL = window.webkitURL || window.URL;
    var contentType = "text/csv";
    var csvFile = new Blob([CSV], {
      type: contentType,
    });
    e.target.href = window.URL.createObjectURL(csvFile);
    e.target.dataset.downloadurl = [
      contentType,
      e.target.download,
      e.target.href,
    ].join(":");
  }

  function openClaim(id, name) {
    if (name.toLowerCase() == "edit") {
        let item = claimList.find((obj) => obj?.id == id);
        onEditClaim(
          item?.id,
          item?.patient_name,
          item?.custom_claim_id,
          item?.patient_id
        );
    }
    if (name.toLowerCase() == "custom_claim_id") {
        let item = claimList.find((obj) => obj?.id == id);
      onEditClaim(
        item?.id,
        item?.patient_name,
        item?.custom_claim_id,
        item?.patient_id
        );
    }
    
    if (name.toLowerCase() == "patient_name") {
      if (checkPermission(permission_key_values_patient.patient_list_sub_module_view)) {
        let item = claimList.find((obj) => obj?.id == id);
        onPatientNameClick(item?.patient_id);
      } else {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      }
      }
    
    
    if (name.toLowerCase() == "responsible_payer") {
      let item = claimList.find((obj) => obj?.id == id);
      const payerPk = item?.payer_types.pk;
      history.push(ROUTE_INSURANCE_COMPANIES_EDIT, { selectedID: payerPk });
    }
  }

  return (
    <React.Fragment>
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <div className="col-md-12" style={{ overflowX: "hidden" }}>
        <div className="basic-info-padding " style={{ minHeight: "700px" }}>
          <input
            name="claimfile"
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="claimfile"
            onChange={onHandleCSVChange}
            style={{ display: "none" }}
          />
          <div className="box-head padding-left5">
            <TabContext value={activeTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabList
                  TabIndicatorProps={{
                      children: <CustomIndicator />
                  }}
                  onChange={onTabChange}
                  scrollable
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                  variant="scrollable"
                >
                  
                  {checkPermission(permission_key_values_claim.claims_search_claims_view) &&
                    <Tab
                      label={i18n.t("searchClaims.last10")}
                      name={"last10"}
                      value={"listLast10Claims"}
                    className="tabMainMenu"
                    />
                  }
                  {checkPermission(permission_key_values_claim.claims_search_tab_module_view) &&
                    <Tab
                      label={i18n.t("searchClaims.search")}
                      name={"search"}
                      value={"listClaims"}
                    className="tabMainMenu"
                    />
                  }
                  {checkPermission(
                  permission_key_values_claim.claims_search_claims_add
                ) &&
                  tabsOpen < MAX_OPEN_TABS_CLAIMS && (
                    
                    <Tab
                    label={
                  <span className="closeIconCommonTab">
                    <IconButton name="add_new" onClick={() => { onTabChange({ target: { name: 'add_new' } }, "add_new") }} aria-label="add-new">
                      <AddIcon
                        className=""
                        style={{
                          fontSize: '2rem',
                          fontWeight: 400,
                        }}
                      />
                    </IconButton>
                      </span>
                    }
                    value={"add_new"}
                    name="add_new"
                    className="tabMainMenu"
                  />
                      )}
                  {selectedClaims &&
                    selectedClaims.map((item, index) => {
                      if (
                        item.pk !== "search" &&
                        item.pk !== "add_new" &&
                        item.pk !== "last10"
                      ) {
                        return (
                          <Tab
                            key={index}
                            className="justify-normal d-inline tabMainMenu"
                            value={"editClaims" + item.pk}
                            name={"editClaims " + item.pk + " " + item.claim_id}
                            label={
                              <>
                                {item.name + ""}
                                <i
                                  className="fa fa-times closeIconCommonTab ml-2 pt-4"
                                  onClick={(e) => closeClaimTab(e, item)}
                                />
                              </>
                            }
                          />
                        );
                      }
                    })}
                  {selectedClaimType === "professional" && (
                    <Tab
                      value={"editClaims"}
                      label={
                        <div className="justify-normal">
                          <span
                            onClick={() =>
                              onTabChange(
                                "",
                                "editClaims",
                                "add_new",
                                "add_new"
                              )
                            }
                          >
                            {i18n.t("searchClaims.claimNavBar.professional")}
                          </span>
                          <span
                            className="closeIconCommonTab margin-left15"
                            onClick={(e) => closeClaimTab(e, { pk: "add_new" })}
                          >
                            <i className="fa fa-times" />
                          </span>
                        </div>
                      }
                      className="tabMainMenu"
                      name={"add_new"}
                    />
                  )}
                  </StyledTabList>
                {(activeTab == "listLast10Claims" ||
                  activeTab == "listClaims") && (
                    <div className="d-flex searchListIcons">
                      {activeTab == "listLast10Claims" && (
                        <div className="div-float-right mr-3 margin-export-icon" style={{backgroundColor: "#FFFFFF", borderRadius: "4px"}}>
                          {/* permission checking */}
                          {checkPermission(
                            permission_key_values_claim.claims_search_claims_add
                          ) && (
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip
                                    id="download-tooltip"
                                    style={{
                                      opacity: 0.4,
                                    }}
                                  >
                                    {i18n.t("commons.importCSV")}
                                  </Tooltip>
                                }
                              >
                            <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)} />
                              </OverlayTrigger>
                            )}
                        </div>
                      )}
                      <div
                        className="dropdown margin-right50"
                        id="export-dropdown"
                      >
                      <div className="margin-top2" style={{backgroundColor: "#FFFFFF", borderRadius: "4px"}}>
                          {checkPermission(
                            permission_key_values_claim.claims_search_claims_view
                          ) && (
                            <ExportIconButton
                              disabled={!claimList.length}
                              onExportCsv={(e) =>
                                OnExportMenuItemClicked(e, "csv")
                              }
                              onExportXls={(e) =>
                                OnExportMenuItemClicked(e, "xls")
                              }
                            />
                            )}
                      </div>
                      </div>
                    </div>
                  )}
              </Box>
              {checkPermission(permission_key_values_claim.claims_search_claims_view) &&
                activeTab === "listLast10Claims" && (
                <TabPanel value={"listLast10Claims"} className="bordering">
                  {/* ************* DOWNLOAD DROPDOWN - START ************** */}
                  {(activeTab === "listLast10Claims" ||
                    activeTab === "listClaims") &&
                    claimList &&
                    claimList.length > 0 && <></>}
                  {/* ************* DOWNLOAD DROPDOWN - END **************** */}
                  <div className="" style={{overflowY:"scroll"}}>
                    <Table
                      tableObject={SearchClaimTableData}
                      onLinkClick={openClaim}
                      dropDownFunction={openClaim}
                      sortingFunction={sortingFunction}
                    />
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                    <div className="clear-b-scroll-sector">&nbsp;</div>
                  </div>
                </TabPanel>
              )}
              {checkPermission(permission_key_values_claim.claims_search_tab_module_view) &&
                activeTab === "listClaims" && (
                <TabPanel value={"listClaims"} className="bordering">
                  <SearchClaim1
                    onEditClaim={onEditClaim}
                    onShowActionButtons={onShowActionButtonsClaim}
                    initialTableData={initialTableData}
                    onHideActionButtons={onHideActionButtonsClaim}
                    activeTab={activeTab}
                    addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                    claimSearchQuery={claimSearchQuery}
                    onShowExport={onShowExport}
                    remShowExport={remShowExport}
                    onHandleCSVExportClaims={onHandleCSVExportClaims}
                    onHandlePDFExportClaims={onHandlePDFExportClaims}
                    onHandleXLSExportClaims={onHandleXLSExportClaims}
                  />
                </TabPanel>
              )}
              {selectedClaimType && (
                <TabPanel
                  value={"editClaims"}
                  className="bordering mt-0 padding-bottom60"
                >
                  <ProfessionalClaims
                    setSelectedClaimType={setSelectedClaimType}
                    addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                    activeTab={activeTab}
                    claimPK={""}
                    newClaimAction={newClaimAction}
                    setProcedureRows={setProcedureRows}
                  />
                </TabPanel>
              )}

              {selectedClaims &&
                selectedClaims.map((item, index) => {
                  if (
                    item.pk &&
                    item.pk !== "search" &&
                    item.pk !== "add_new" &&
                    item.pk !== "last10" &&
                    activeTab === "editClaims" + item.pk
                  ) {
                    return (
                      <TabPanel
                        key={index}
                        value={"editClaims" + item.pk}
                        className="bordering mt-0 padding-bottom60"
                      >
                        <ProfessionalClaims
                          setSelectedClaimType={setSelectedClaimType}
                          activeSubTab={item.active_tab}
                          addRemoveFromSelectedTab={addRemoveFromSelectedTab}
                          claimPK={item.pk}
                          claimID={item.claim_id}
                          activeTab={activeTab}
                          newClaimAction={newClaimAction}
                          setProcedureRows={setProcedureRows}
                        />
                      </TabPanel>
                    );
                  }
                })}
            </TabContext>
          </div>
        </div>
      </div>
      <CustomizedSmallDialogs
        showModal={showAlertModalWindow}
        header={i18n.t("commons.alert")}
        alertOK={onAlertOk}
        type="alert"
        setShowModalWindow={setShowAlertModalWindow}
      >
        <div className="display-grid">{ReactHtmlParser(alertMessage)}</div>
      </CustomizedSmallDialogs>
      <CustomizedSmallDialogs
        showModal={showImportErrorModalWindow}
        header={i18n.t("commons.importErrorHeader")}
        alertOK={onImportErrorOK}
        onDownload={onDownloadCSV}
        type="alert"
        setShowModalWindow={setShowImportErrorModalWindow}
      >
        <div className="display-grid">
          {ReactHtmlParser(importErrorMessage)}
        </div>
      </CustomizedSmallDialogs>
    </React.Fragment>
  );
}

export default SearchClaims;