import React, { useEffect, useState , useContext} from 'react'
import { Form } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router-dom';
import i18n from '../../../utilities/i18n';
import CalendarInput from '../../commons/input/CalendarInput';
import TextInput from '../../commons/input/input';
import SelectInput from '../../commons/input/SelectInput';
import service from './service';
import { getStorage, setStorage } from '../../../utilities/browserStorage';
import format from 'date-fns/format'
import { ACTIVE_STATUS } from '../../../utilities/dictionaryConstants';
import Pagination from '../../commons/pagination';
import { PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ROUTE_INSURANCE_COMPANIES_EDIT, ROUTE_PATIENTS_LIST } from '../../../utilities/staticConfigs';
import RightSidePanel from '../../commons/RightSidePanel/RightSidePanel';
import PatientsAdvancedSearch from '../../patientManagement/patients/PatientsAdvancedSearch';
import PatientDropDownSearch from '../../patientManagement/patients/patientDropDownSearch';
import Table from '../../commons/Table/Table';
import { SearchClaimTableData, TableBodyData } from './SearchClaimTable';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import LoadingContext from '../../../container/loadingContext';
import { permission_key_values_claim } from '../../../utilities/permissions';
import Notify from '../../commons/notify';
import CommonButton from '../../commons/Buttons';

function SearchClaim1(props) {
  const history = useHistory();
  const [searchByClaimId, setSearchByClaimId] = useState("");
  const [dateOfServiceFrom, setDateOfServiceFrom] = useState("");
  const [dateOfServiceTo, setDateOfServiceTo] = useState("");
  const [claimEnteredFrom, setClaimEnteredFrom] = useState("");
  const [claimEnteredTo, setClaimEnteredTo] = useState("");
  const [claimList, setClaimList] = useState([]);
  const [redirectToPatient, setRedirectToPatient] = useState(false);
  const [activeStatus, setActiveStatus] = useState("1");
  const [patientPK, setPatientPK] = useState("");
  const [patientSelected, setPatientSelected] = useState([]);
  const SearchClaimTableOpened = getStorage("SearchClaimTableOpened");
  //Pagination start
  const [totalPage, setTotalPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);
  const [patientAdvSearchData, setPatientAdvSearchData] = useState([]);
  const [resetCount, setResetCount] = useState(0);

  // table column sorting
  const [orderType, setOrderType] = useState("");
  const [initialOrderingPatientName, setInitialOrderingPatientName] = useState(true);
  const [initialOrderingClaimId, setInitialOrderingClaimId] = useState(true);
  const [initialOrderingDos, setInitialOrderingDos] = useState(true);
  const [initialOrderingBillDate, setInitialOrderingBillDate] = useState(true);
  SearchClaimTableData.tableHeadings[0].initialOrdering =
    initialOrderingClaimId;
  SearchClaimTableData.tableHeadings[0].orderType = orderType;
  SearchClaimTableData.tableHeadings[1].initialOrdering =
    initialOrderingPatientName;
  SearchClaimTableData.tableHeadings[1].orderType = orderType;
  SearchClaimTableData.tableHeadings[2].initialOrdering = initialOrderingDos;
  SearchClaimTableData.tableHeadings[2].orderType = orderType;
  SearchClaimTableData.tableHeadings[5].initialOrdering =
    initialOrderingBillDate;
  SearchClaimTableData.tableHeadings[5].orderType = orderType;

  const setShowLoadingBar = useContext(LoadingContext);
  const [showNotify, setShowNotify] = useState("hide");
  const [notifyDescription, setNotifyDescription] = useState("");
  const [notifyType, setNotifyType] = useState("success");

  useEffect(() => {
    let newWidth = "";
    newWidth = window.innerWidth;
    let menuWidth = document
      .getElementById("push-bar")
      .classList.contains("open");
    if (!menuWidth) {
      newWidth = newWidth + 250;
    } else {
      newWidth = window.innerWidth;
    }
    return newWidth;
  }, []);

  function onPagePrevious() {
    let previousPage = startIndex + 1 - PAGING_END_INDEX;
    setActivePage(previousPage);
    if (startIndex !== 0) {
      setStartIndex(startIndex - PAGING_END_INDEX);
      setEndIndex(endIndex - PAGING_END_INDEX);
    }
    ListClaims(DEFAULT_PAGING_SIZE, previousPage);
  }

  function onPageUp(e) {
    let page = Number(e.target.id);
    setActivePage(page);
    ListClaims(DEFAULT_PAGING_SIZE, page);
  }

  function onPageNext() {
    let nextPage = startIndex + 1 + PAGING_END_INDEX;
    if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
      setActivePage(nextPage);
      setStartIndex(startIndex);
      setStartIndex(endIndex);
    } else {
      setActivePage(nextPage);
      setStartIndex(startIndex + PAGING_END_INDEX);
      setEndIndex(endIndex + PAGING_END_INDEX);
    }
    ListClaims(DEFAULT_PAGING_SIZE, nextPage);
  }
  //Pagination ends

  function showNotifyWindow(action, type, desc, age = 3000) {
    if (action == "show") {
      setTimeout(() => {
        setShowNotify("hide");
      }, age);
    }
    setShowNotify(action);
    setNotifyType(type);
    setNotifyDescription(desc);
  }

  function onHandleChange(e) {
    if (e.target) {
      let name = e.target.name;
      let value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;
      let trimmedValue = "";

        trimmedValue = value;
      if (name === "searchByClaimId") setSearchByClaimId(trimmedValue);
      else if (name === "activeStatus") setActiveStatus(trimmedValue);
    } else {
      if (e[0]) {
        setPatientPK(e[0].id);
      } else if (!e[0]) {
        setPatientPK("");
      }
      setPatientSelected(e);
    }
  }
  function onHandleDateOfService(selected) {
    setDateOfServiceFrom(selected);
  }
  function onHandleDateOfService2(selected) {
    setDateOfServiceTo(selected);
  }
  function onHandleClaimEnteredFrom(selected) {
    setClaimEnteredFrom(selected);
  }
  function onHandleClaimEnteredTo(selected) {
    setClaimEnteredTo(selected);
  }

  function ListClaims(pageSize, page) {
    let dos_from = "";
    let dos_to = "";
    let claim_from = "";
    let claim_to = "";
    try {
      dos_from = dateOfServiceFrom
        ? format(dateOfServiceFrom, "yyyy-MM-dd")
        : "";
      dos_to = dateOfServiceTo ? format(dateOfServiceTo, "yyyy-MM-dd") : "";
      claim_from = claimEnteredFrom
        ? format(claimEnteredFrom, "yyyy-MM-dd")
        : "";
      claim_to = claimEnteredTo ? format(claimEnteredTo, "yyyy-MM-dd") : "";
    } catch {
      return;
    }
    setShowLoadingBar(true);
    let query =
      "?page_size=" +
      pageSize +
      "&page=" +
      page +
      "&practice_pk=" +
      getStorage("practice") +
      "&claim_id=" +
      searchByClaimId +
      "&active=" +
      activeStatus +
      "&claim_from=" +
      claim_from +
      "&claim_to=" +
      claim_to +
      "&list=true&patient_pk=" +
      patientPK +
      "&dos_from=" +
      dos_from +
      "&dos_to=" +
      dos_to;

    // table column sort
    if (
      !initialOrderingPatientName ||
      !initialOrderingClaimId ||
      !initialOrderingDos ||
      !initialOrderingBillDate
    ) {
      let fieldName = "";
      if (!initialOrderingPatientName) {
        fieldName = "patient__full_name";
      } else if (!initialOrderingClaimId) {
        fieldName = "custom_claim_id";
      } else if (!initialOrderingDos) {
        fieldName = "service_from_date";
      } else if (!initialOrderingBillDate) {
        fieldName = "last_billed_date";
      }
      if (fieldName) {
        query += "&column=" + orderType + fieldName;
      }
    }

    let item = {
      pk: "search",
      claim_id: "search",
      type: "claims",
      action: "add",
      practice_pk: getStorage("practice"),
      query: query,
    };
    props.addRemoveFromSelectedTab(item);
    const result = service.GetListPatientClaims(query);
    result.then((response) => {
      setStorage("SearchClaimTableOpened", true);
      if (response?.data?.results?.length) {
        const rowArray = commonTableBody(
          response.data.results,
          SearchClaimTableData.tableBodyData[0]
        );
        SearchClaimTableData.tableBodyData = rowArray;
        setTotalPage(Math.ceil(response.data.count / response.data.page_size));
      } else {
        SearchClaimTableData.tableBodyData = TableBodyData;
      }
      setTimeout(() => {
        setClaimList(response?.data?.results || []);
        setShowLoadingBar(false);
      }, 100);
    }).catch(()=> {
      SearchClaimTableData.tableBodyData = TableBodyData;
    }).finally(()=> {
      setShowLoadingBar(false);
    })
  }

  function resetSorting(){
    setInitialOrderingPatientName(true);
      setInitialOrderingClaimId(true);
      setInitialOrderingDos(true);
      setInitialOrderingBillDate(true);
  }

  function sortingFunction(e, name) {
    name = name.replace(" ", "_").toLowerCase();
    if (name == "patient_name") {
      setInitialOrderingPatientName(false);
      setInitialOrderingClaimId(true);
      setInitialOrderingDos(true);
      setInitialOrderingBillDate(true);
    } else if (name == "claim_id") {
      setInitialOrderingClaimId(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingDos(true);
      setInitialOrderingBillDate(true);
    } else if (name == "dos") {
      setInitialOrderingDos(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingClaimId(true);
      setInitialOrderingBillDate(true);
    } else if (name == "billed_date") {
      setInitialOrderingBillDate(false);
      setInitialOrderingPatientName(true);
      setInitialOrderingClaimId(true);
      setInitialOrderingDos(true);
    }

    if (!orderType) setOrderType("-");
    else setOrderType("");
  }

  useEffect(() => {
    ListClaims(DEFAULT_PAGING_SIZE,activePage);
  }, [orderType]);

  useEffect(() => {
    if (resetCount > 0) {
      ListClaims(DEFAULT_PAGING_SIZE,1);
      setResetCount(0);
    }
  },[resetCount]);

  function onSearchClaims() {
    setActivePage(1);
    resetSorting()
    ListClaims(DEFAULT_PAGING_SIZE, 1);
    setOrderType('');
  }

  function onPatientNameClick(patientID) {
    let item = {
      pk: patientID,
      type: "patients",
      action: "add",
      practice_pk: getStorage("practice"),
    };
    props.addRemoveFromSelectedTab(item);
    setRedirectToPatient(true);
  }


  function openClaim(id, name) {
    if (name.toLowerCase() == "edit") {
      let item = claimList?.find((obj) => obj.id == id);
      if (item) {
        props.onEditClaim(
          item.id,
          item.patient_name,
          item.custom_claim_id,
          item.patient_id
        );
      }
    }
    if (name.toLowerCase() == "custom_claim_id") {
      let item = claimList?.find((obj) => obj.id == id);
      if (item) {
        props.onEditClaim(
          item.id,
          item.patient_name,
          item.custom_claim_id,
          item.patient_id
        );
      }
    }
    if (name.toLowerCase() == "patient_name") {
      if (checkPermission(permission_key_values_claim.patient_list_sub_module_view)) {
        let item = claimList?.find((obj) => obj.id == id);
        onPatientNameClick(item.patient_id);
      }
      else {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
      }
    }
    if (name.toLowerCase() == "responsible_payer") {
      let item = claimList?.find((obj) => obj.id == id);
      const payerPk = item.payer_types.pk;
      history.push(ROUTE_INSURANCE_COMPANIES_EDIT, { selectedID: payerPk });
    }
  }

  if (redirectToPatient) {
    return <Redirect to={{ pathname: ROUTE_PATIENTS_LIST }} />;
  }

  function onClearSearch() {
    setSearchByClaimId("");
    setActiveStatus("1");
    setDateOfServiceFrom("");
    setDateOfServiceTo("");
    setClaimEnteredFrom("");
    setClaimEnteredTo("");
    setActivePage(1);
    setClaimList([]);
    setPatientSelected("");
    setPatientPK("");
    setResetCount(resetCount + 1);
    document.querySelector('button[aria-label="Clear"]')?.click();
  }


  return (
    <div className="">
      <Notify
        showNotify={showNotify}
        setShowNotify={setShowNotify}
        notifyDescription={notifyDescription}
        setNotifyType={setNotifyType}
        setNotifyDescription={setNotifyDescription}
        notifyType={notifyType}
      />
      <Form autoComplete="off">
        <div
          className="searchBox mt-2 px-4 pt-5 pb-3"
          style={{ backgroundColor: "#fbfbfb" }}
        >
          <div className="row padding-bottom10">
            <PatientDropDownSearch
              setPatientPK={setPatientPK}
              patientSelected={patientSelected}
              setPatientSelected={setPatientSelected}
              patientAdvSearchData={patientAdvSearchData}
            ></PatientDropDownSearch>
            <div className="col pl-0 padding-top23">
              <div className="input-content-box padding-top2">
                <RightSidePanel
                  title={i18n.t("commons.advancedSearch")}
                  onclickLabel={i18n.t("commons.advancedSearch")}
                >
                  <PatientsAdvancedSearch
                    setPatientPK={setPatientPK}
                    setPatientSelected={setPatientSelected}
                    setPatientAdvSearchData={setPatientAdvSearchData}
                  ></PatientsAdvancedSearch>
                </RightSidePanel>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="form-group">
                <TextInput
                  id="basic-typeahead-single"
                  name="searchByClaimId"
                  label={i18n.t("searchClaims.searchByClaimId")}
                  value={searchByClaimId}
                  onValueChange={onHandleChange}
                />
              </div>
            </div>

            <div className="col-3 pl-0 margin-left20">
              <div className="form-group">
                <SelectInput
                  data={ACTIVE_STATUS}
                  name="activeStatus"
                  value={activeStatus}
                  onValueChange={onHandleChange}
                  label={i18n.t("patientPages.patients.labelActiveStatus")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <CalendarInput
                name="dateOfServiceFrom"
                id="dateOfServiceFrom"
                label={i18n.t("searchClaims.dateOfService")}
                selected={dateOfServiceFrom}
                onValueChange={(selected) => onHandleDateOfService(selected)}
              />
            </div>
            <div className="col-3 pl-0 margin-left20">
              <CalendarInput
                name="dateOfServiceTo"
                minDate={dateOfServiceFrom}
                id="dateOfServiceTo"
                label={i18n.t("searchClaims.to")}
                selected={dateOfServiceTo}
                onValueChange={(selected) => onHandleDateOfService2(selected)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <CalendarInput
                name="claimEnteredFrom"
                id="claimEnteredFrom"
                label={i18n.t("searchClaims.claimEnteredDate")}
                selected={claimEnteredFrom}
                onValueChange={(selected) => onHandleClaimEnteredFrom(selected)}
              />
            </div>
            <div className="col-3 pl-0 margin-left20">
              <CalendarInput
                name="claimEnteredTo"
                minDate={claimEnteredFrom}
                id="claimEnteredTo"
                label={i18n.t("searchClaims.to")}
                selected={claimEnteredTo}
                onValueChange={(selected) => onHandleClaimEnteredTo(selected)}
              />
            </div>
            <div className="col padding-top26 justify-right">
              <div style={{ marginLeft: 12 }}>
                <CommonButton label={i18n.t("commons.clear")} variant="outlined" onClick={onClearSearch} />
              </div>
              <div style={{ marginLeft: 12 }}>
                <CommonButton label={i18n.t("searchClaims.searchClaims")} variant="contained" onClick={onSearchClaims} />
              </div>
            </div>
          </div>
        </div>
        <div className="padding-top5 mt-3" style={{
          overflowX: "auto", width: "100%"
        }}>
          {SearchClaimTableOpened && (
            <div>
              <Table
                tableObject={SearchClaimTableData}
                onLinkClick={openClaim}
                dropDownFunction={openClaim}
                sortingFunction={sortingFunction}
              />
            </div>
          )}
          {/* </div> */}
          {totalPage <= 1 && (
            <div className="clear-b-scroll-sector">&nbsp;</div>
          )}
          <div className="clear-b-scroll-sector">&nbsp;</div>
        </div>
       
        <div className='padding-top10'></div>
        {claimList.length > 0 ? 
        <Pagination
          totalPage={totalPage}
          activePage={activePage}
          startIndex={startIndex}
          endIndex={endIndex}
          onPagePrevious={onPagePrevious}
          onPageUp={onPageUp}
          onPageNext={onPageNext}
        />
      : <></> } 
      </Form>
    </div>
  );
}

export default SearchClaim1
