import React, { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useLocation  } from 'react-router-dom';

import service from './service'
import { AdjustmentReasonCodeTableObject, AdjustmentReasonCodeTableAdminObject, AdjustmentReasonCodeTableBody, AdjustmentReasonCodeTableBodyAdmin, ARCAddTableObject } from './AdjustmentReasonCodeTable';

import LoadingContext from '../../../container/loadingContext';

import CustomizedDialogs from '../../modalWindowComponent/CustomizedDialogs';

import Notify from '../../commons/notify';
import Table from '../../commons/Table/Table';
import TextInput from '../../commons/input/input';
import Pagination from '../../commons/pagination';
import BackArrowWithLabel from "../../commons/Back"; 
import CommonButton from "../../commons/Buttons";

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import i18n from '../../../utilities/i18n';
import { csvFileCheck } from '../../../utilities/validations';
import { getStorage } from '../../../utilities/browserStorage';
import { checkPermission, commonTableBody } from '../../../utilities/commonUtilities';
import { ADD_SUCCESS, ADD_ERROR, UPDATE_SUCCESS, DELETE_SUCCESS } from '../../../utilities/labelConfigs';
import { ROUTE_SUPER_DICTIONARIES, PAGING_END_INDEX, DEFAULT_PAGING_SIZE, ADJUSTMENT_REASON_CODE_CSV_NAME, ROUTE_CODE_MANAGEMENT, ROUTE_ADJUSTMENT_REASON_CODE } from '../../../utilities/staticConfigs';
import CustomizedSmallDialogs from '../../modalWindowComponent/CustomisedSmallDialog';
import ExportIconButton from '../../commons/exportIconButton';
import { permission_key_values_accountsetup, super_admin_permission_key_value, super_admin_privileges } from '../../../utilities/permissions';

const AdjustmentReasonCode = (props) => {
    const isAdminModule = getStorage('isAdminModule');
    const location = useLocation();
    const practicePK = getStorage("practice");
    const [showModalWindow, setShowModalWindow] = useState(false);
    const [showDeleteModalWindow, setShowDeleteModalWindow] = useState(false);
    const [adjustmentReasonCode, setAdjustmentReasonCode] = useState('');
    const [description, setDescription] = useState('');
    const [deleteAdjustmentReasonCode, setDeleteAdjustmentReasonCode] = useState('');
    const [deleteAdjustmentReasonCodeId, setDeleteAdjustmentReasonCodeId] = useState('');
    const [editAdjustmentReasonCodeId, setEditAdjustmentReasonCodeId] = useState('');
    const [adjustmentReasonCodeList, setAdjustmentReasonCodeList] = useState([]);
    const [form_error, setFormError] = useState({ 'adjustmentReasonCode': '', 'description': '' });
    const [editForm, setEditForm] = useState(false);
    const [header, setHeader] = useState('')
    const [searchValue, setSearchValue] = useState('');
    const [searchValueClose, setSearchValueClose] = useState(0);
    const [orderType, setOrderType] = useState('');
    const [orderingField, setOrderingField] = useState('name'); /* show the triangle only in clicked header */
    const [initialOrdering, setInitialOrdering] = useState(true);
    if (isAdminModule === 'true') {
        AdjustmentReasonCodeTableAdminObject.tableHeadings[0].initialOrdering = initialOrdering;
        AdjustmentReasonCodeTableAdminObject.tableHeadings[0].orderType = orderType;
    } else {
        AdjustmentReasonCodeTableObject.tableHeadings[0].initialOrdering = initialOrdering;
        AdjustmentReasonCodeTableObject.tableHeadings[0].orderType = orderType;
    }

    const [showMasterReasonsModal, setShowMasterReasonsModal] = useState(false);
    const [searchReasonCode, setSearchReasonCode] = useState('');
    const [masterReasonCodeList, setMasterReasonCodeList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    const [permission, setPermission] = useState(false);
    useEffect(() => {
        if(location.pathname === ROUTE_ADJUSTMENT_REASON_CODE){
            setPermission(checkPermission(super_admin_privileges.super_admin_full_privilege,super_admin_permission_key_value.dictionary_add))
        } else {
            setPermission(checkPermission(permission_key_values_accountsetup.account_setup_code_management_add,
                 permission_key_values_accountsetup.account_setup_code_management_modify,
                  super_admin_privileges.super_admin_full_privilege));
        }
    }, [])

    //start ==== Alert message properties
    const [showNotify, setShowNotify] = useState('hide');
    const [notifyDescription, setNotifyDescription] = useState('');
    const [notifyType, setNotifyType] = useState('success');
    const setShowLoadingBar = useContext(LoadingContext);

    function showNotifyWindow(action, type, desc, age = 3000) {
        if (action === 'show') {
            setTimeout(() => {
                setShowNotify('hide');
            }, age)
        }
        setShowNotify(action);
        setNotifyType(type);
        setNotifyDescription(desc);

    }
    // end

    //Pagination start
    const [totalPage, setTotalPage] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(PAGING_END_INDEX);

    function onPagePrevious() {
        let previousPage = startIndex + 1 - PAGING_END_INDEX;
        setActivePage(previousPage);

        if (startIndex !== 0) {
            setStartIndex(startIndex - PAGING_END_INDEX);
            setEndIndex(endIndex - PAGING_END_INDEX);
        }
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, previousPage, orderingField);
    }

    function onPageUp(e) {
        let page = Number(e.target.id)
        setActivePage(page);
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, page, orderingField);
    }

    function onPageNext() {
        let nextPage = startIndex + 1 + PAGING_END_INDEX;
        if (endIndex === totalPage || totalPage <= PAGING_END_INDEX) {
            setActivePage(nextPage);
            setStartIndex(startIndex);
            setStartIndex(endIndex);

        } else {
            setActivePage(nextPage);
            setStartIndex(startIndex + PAGING_END_INDEX);
            setEndIndex(endIndex + PAGING_END_INDEX);

        }
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, nextPage, orderingField);

    }
    //Pagination ends

    //Master Pagination start
    const [masterTotalPage, setMasterTotalPage] = useState(1);
    const [masterActivePage, setMasterActivePage] = useState(1);
    const [masterStartIndex, setMasterStartIndex] = useState(0);
    const [masterEndIndex, setMasterEndIndex] = useState(PAGING_END_INDEX);

    function onMasterPagePrevious() {
        let previousPage = masterStartIndex + 1 - PAGING_END_INDEX;
        setMasterActivePage(previousPage);

        if (masterStartIndex !== 0) {
            setMasterStartIndex(masterStartIndex - PAGING_END_INDEX);
            setMasterEndIndex(masterEndIndex - PAGING_END_INDEX);
        }
        getMasterReasonsList(DEFAULT_PAGING_SIZE, previousPage);
    }

    function onMasterPageUp(e) {
        let page = Number(e.target.id)
        setMasterActivePage(page);
        getMasterReasonsList(DEFAULT_PAGING_SIZE, page);
    }

    function onMasterPageNext() {
        let nextPage = masterStartIndex + 1 + PAGING_END_INDEX;
        if (masterEndIndex === masterTotalPage || masterTotalPage <= PAGING_END_INDEX) {
            setMasterActivePage(nextPage);
            setMasterStartIndex(masterStartIndex);
            setMasterStartIndex(masterEndIndex);
        } else {
            setMasterActivePage(nextPage);
            setMasterStartIndex(masterStartIndex + PAGING_END_INDEX);
            setMasterEndIndex(masterEndIndex + PAGING_END_INDEX);
        }
        getMasterReasonsList(DEFAULT_PAGING_SIZE, nextPage);
    }
    //Master Pagination ends

    function addNew() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setHeader(i18n.t("dictionariesPages.dictAdjustmentReasonCode.addHeader"));
        setShowModalWindow(true);
    }

    function onHide() {
        setEditForm(false);
    }
    function onDeleteHide() {
        setDeleteAdjustmentReasonCode('');
        setDeleteAdjustmentReasonCodeId('');
        setShowDeleteModalWindow(false);
    }

    function getAdjustmentReasonCodeData(pageSize, page, fieldName, sorting) {
        const fieldOrderType = sorting ? (!orderType ? '-' : '') : orderType;
        setShowLoadingBar(true);
        const result = service.GetListAdjustmentReasonCode(pageSize, page, isAdminModule, practicePK, false, searchValue,fieldName, fieldOrderType, 'yes');
        result.then(async response => {
            if (response.data.results !== undefined) {
                setTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }
            setShowLoadingBar(false);
            //get row data to populate table
            let rowArray=await getRowArray(response.data)

            if (rowArray.length > 0) {
                if (isAdminModule === 'true') {
                    AdjustmentReasonCodeTableAdminObject.tableBodyData = rowArray;
                }
                else {
                    AdjustmentReasonCodeTableObject.tableBodyData = rowArray;
                }
            }
            else {
                if (isAdminModule === 'true') {
                    AdjustmentReasonCodeTableAdminObject.tableBodyData = AdjustmentReasonCodeTableBodyAdmin;
                }
                else {
                    AdjustmentReasonCodeTableObject.tableBodyData = AdjustmentReasonCodeTableBody;
                }
            }

            setAdjustmentReasonCodeList(response.data.results);
        });
    }

    //generating a table format data
    function getRowArray(data){
        let anArray = [];
            let rowArray = [];
            let newData = (isAdminModule === 'true') ? Array(data?.results?.length).fill(JSON.parse(JSON.stringify(AdjustmentReasonCodeTableAdminObject.tableBodyData[0]))) : 
            Array(data?.results?.length).fill(JSON.parse(JSON.stringify(AdjustmentReasonCodeTableObject.tableBodyData[0])));
            newData.map((row, rowIndex) => {
                row.map((col) => {
                    let colObject = {};
                    colObject = {
                        ...col, 'value': data?.results[rowIndex][col.name],
                        "id": data?.results[rowIndex].id
                    }
                    anArray.push(colObject)
                })
                rowArray.push(anArray)
                anArray = [];
            });
        return rowArray;
    }

    useEffect(() => {
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
    }, [searchValueClose]);

    function dropDownFunction(id, name) {
        if (!permission) {
            showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
            return;
        }
        if (name.toLowerCase() == 'edit') {
            onEditAdjustmentReasonCode(Number(id));
        } else if (name.toLowerCase() == 'delete') {
            onDeleteAdjustmentReasonCode(Number(id));
        }
    }

    function onEditAdjustmentReasonCode(id) {
        setShowLoadingBar(true);
        let data = null;
        data = service.GetAdjustmentReasonCode(id);
        data.then(response => {
            setShowLoadingBar(false);
            setHeader(i18n.t("dictionariesPages.dictAdjustmentReasonCode.editHeader"));
            setShowModalWindow(true);
            setAdjustmentReasonCode(response.data.name);
            setDescription(response.data.description);
            setEditForm(true);
            setEditAdjustmentReasonCodeId(id);
        });
    }

    function onDeleteAdjustmentReasonCode(id) {
        adjustmentReasonCodeList.map((item) => {
            if (item.id === parseInt(id)) {
                setDeleteAdjustmentReasonCode(item.name);
                setDeleteAdjustmentReasonCodeId(item.id);
                setShowDeleteModalWindow(true);
            }

        });
    }

    function onDeleteAction() {
        setShowLoadingBar(true);
        let data = null;
        if (isAdminModule === 'true')
            data = service.DeleteAdjustmentReasonCode(deleteAdjustmentReasonCodeId);
        else
            data = service.DeleteAdjustmentReasonCode(deleteAdjustmentReasonCodeId, practicePK);
        data.then(() => {
            setShowLoadingBar(false);
            setDeleteAdjustmentReasonCode('');
            setDeleteAdjustmentReasonCodeId('');
            setShowDeleteModalWindow(false);
            showNotifyWindow('show', 'success', DELETE_SUCCESS);
            getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
        });
    }

    function resetForm() {
        setAdjustmentReasonCode('');
        setDescription('');
        setEditForm(false);
        setEditAdjustmentReasonCodeId("");
        setFormError({
            ...form_error,
            ['adjustmentReasonCode']: '',
            ['description']: ''
        });

    }
    function onSaveFormData(e, saveType) {
        e.preventDefault();
        if (selectedIds.length == 0 && isAdminModule=='false') return;
        if (saveType === 'master') {
            setFormError({
                ...form_error,
                ['adjustmentReasonCode']: !adjustmentReasonCode.trim() ? 'error' : "",
                ['description']: !description ? 'error' : ""
            });
            if (adjustmentReasonCode && description) {
                setShowLoadingBar(true);
                let result = null;
                let data = null;
                data= isAdminModule === 'true' ? { name: adjustmentReasonCode, description: description } : { name: adjustmentReasonCode, description: description, practice: practicePK }
                result=editForm ?  service.UpdateAdjustmentReasonCode(editAdjustmentReasonCodeId, data) : service.AddAdjustmentReasonCode(data);
                result.then(response => {
                    setShowLoadingBar(false);
                    if (response.status === 201 || response.status === 200) {
                        if (response.status === 201)
                            showNotifyWindow('show', 'success', ADD_SUCCESS);
                        else showNotifyWindow('show', 'success', UPDATE_SUCCESS);

                        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
                        resetForm();
                        setShowModalWindow(false);

                    } else if (response.status === 400 && response.data.record_already_exists !== undefined) {
                        showNotifyWindow('show', 'error', i18n.t('errorMessages.record_exists'));
                    } else {
                        showNotifyWindow('show', 'error', ADD_ERROR);
                    }
                });
            }
        } else {
            setShowLoadingBar(true);
            const data = {
                practice_pk: practicePK,
                reason_pks: selectedIds
            }
            const result = service.AddAdjustmentReasonCode(data);
            result.then(() => {
                setActivePage(1);
                setStartIndex(0);
                setEndIndex(PAGING_END_INDEX);
                getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, 1);
                setShowLoadingBar(false);
                setShowMasterReasonsModal(false);
                showNotifyWindow('show', 'success', ADD_SUCCESS);
                setSelectedIds([]);
            });
        }
    }
    
    function onHandleChange(e) {
        const { name } = e.target;
        let value = e.target.value;
        let trimmedValue = '';
        trimmedValue = value;
       
        if (!trimmedValue) {
            setFormError({
                ...form_error,
                [name]: 'error'
            });
        } else {
            setFormError({
                ...form_error,
                [name]: ''
            });
        }
      
        if (name === 'adjustmentReasonCode')
            setAdjustmentReasonCode(trimmedValue);
        else if (name === 'searchValue') setSearchValue(trimmedValue);
        else if (name === 'searchReasonCode') {
            setSearchReasonCode(trimmedValue);
            setMasterActivePage(1);
            getMasterReasonsList(DEFAULT_PAGING_SIZE, 1, trimmedValue, true);
        }
        else { setDescription(trimmedValue) }
      
    }

    function backToDictionaryPage() {
        props.history.push(ROUTE_SUPER_DICTIONARIES)
    }

    function onHandleCsvImport(e) {
        e.preventDefault();
        document.getElementById('file').click();
    }

    function onHandleCSVChange(e) {
        const value =
          e.target.type === "file" ? e.target.files[0] : e.target.value;
      
        if (e.target.type === "file") {
          if (e.target.files[0].name !== ADJUSTMENT_REASON_CODE_CSV_NAME) {
            showInvalidFileNameError();
          } else if (csvFileCheck(e.target.files[0].name)) {
            const data = new FormData();
            data.append('file', value);
            let result = service.ImportCSVData(data);
            result.then(response => {
              setShowLoadingBar(false);
              if (response.data.message !== undefined) {
                handleImportResponse(response.data.message, response.data.existing_name);
              } else {
                showImportFailedError();
              }
            });
          } else {
            showInvalidFileFormatError();
          }
        }
      }
      
      //invalid name error handled here
      function showInvalidFileNameError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_name'));
      }
      
      //invalid file format error handled here
      function showInvalidFileFormatError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_file_format'));
      }
      
      // import error handled here
      function showImportFailedError() {
        showNotifyWindow('show', 'error', i18n.t('errorMessages.import_failed'));
      }
      
      //response of csv file is handled here
      function handleImportResponse(message, existingRecords) {
        if (message === 'no_file_records') {
          showNotifyWindow('show', 'error', i18n.t('errorMessages.empty_file'));
        } else if (message === 'invalid_headings') {
          showNotifyWindow('show', 'error', i18n.t('errorMessages.invalid_headings'));
        } else if (message === 'data_imported') {
          if (existingRecords && existingRecords.length > 0) {
            showNotifyWindow('show', 'warning', i18n.t('errorMessages.record_not_imported') + ' \n ' + existingRecords.join(',\n'));
          } else {
            showNotifyWindow('show', 'success', i18n.t('validations.success.import_success'));
          }
          getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, activePage);
        }
      }
      

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchAdjustmentReasonCode(e);
        }
    }

    function onSearchAdjustmentReasonCode(e, fieldName) {
        e.preventDefault();
        setActivePage(1)
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setInitialOrdering(true);
            setOrderType('');
        }
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, 1, fieldName, true);
    }


    function onSearchAdjustmentReasonCodeSorting(e, fieldName) {
        e.preventDefault();
        setStartIndex(0);
        setEndIndex(PAGING_END_INDEX);
        if (!fieldName) { // if not field name then call from ,search button click
            setInitialOrdering(true);
            setOrderType('');
        }
        getAdjustmentReasonCodeData(DEFAULT_PAGING_SIZE, activePage, fieldName, true);
    }

    const tableSorting = (e) => {
        setInitialOrdering(false);
        let sortingField = 'name';
        setOrderingField(sortingField);
        if (!orderType) setOrderType('-');
        else setOrderType('');
        onSearchAdjustmentReasonCodeSorting(e, sortingField);
    }

    function addNewToPractice() {
        if (!permission) {
			showNotifyWindow('show','error', i18n.t('errorMessages.permission_error'));
			return;
		}
        setShowMasterReasonsModal(true);
        setMasterActivePage(1);
        getMasterReasonsList(DEFAULT_PAGING_SIZE, 1);
    }

    function getMasterReasonsList(pageSize, page, search, fromSearch) {
        setShowLoadingBar(true);
        let result = null;
        if (!fromSearch && searchReasonCode && searchReasonCode.length > 1)
            result = service.GetListAdjustmentReasonCode(pageSize, page, isAdminModule, practicePK, true, searchReasonCode, 'dropdown');
        else if (fromSearch)
            result = service.GetListAdjustmentReasonCode(pageSize, page, isAdminModule, practicePK, true, search, 'dropdown');
        else
            result = service.GetListAdjustmentReasonCode(pageSize, page, isAdminModule, practicePK, true, null, 'dropdown');
        result.then(response => {
            setShowLoadingBar(false);
            if (response.data.results !== undefined) {
                setMasterTotalPage(Math.ceil(response.data.count / response.data.page_size));
            }

            const rowArray = commonTableBody(response.data.results, ARCAddTableObject.tableBodyData[0]);
            ARCAddTableObject.tableBodyData = rowArray;

            setMasterReasonCodeList(response.data.results);
        });
    }

    function resetMatserReasonsData() {
        setSelectedIds([]);
        setSearchReasonCode('');
    }


    function onHandleCheckBoxChange(e, id) {
        ARCAddTableObject.tableBodyData.forEach(rowItem => {
            if (rowItem.find(colItem => colItem.id == id)) {
                let index = rowItem.findIndex(cItem => cItem.type == 'checkbox');
                rowItem[index].value = e.target.checked;
            }
        });
        if (e.target.checked) {
            if (selectedIds.indexOf(id) < 0) {
                setSelectedIds([...selectedIds, id]);
            }
        }
        else {
            const index = selectedIds.indexOf(id);
            if (index > -1) {
                let newIds = selectedIds.filter(item => Number(item) != Number(id))
                setSelectedIds(newIds);
            }
        }
    }

    function backToCodes() {
        props.history.push(ROUTE_CODE_MANAGEMENT)
    }

    function onClickGridCheckBox(e, clickedFrom, id) {
        if (clickedFrom == 'data') {
            onHandleCheckBoxChange(e, id);
        }
    }
    const OnExportMenuItemClicked = (e, type) => {
        // eslint-disable-next-line no-undef
        const fileDownload = require('js-file-download');
        setShowLoadingBar(true);
        service.exportAdjustmentResonCode(type, practicePK)
            .then((result) => {
                if (result.status === 200 || result.status === 201) {
                    fileDownload(result.data, result.headers['content-disposition']);
                    setShowLoadingBar(false);
                    showNotifyWindow('show', 'success', 'Export Success');
                } else if (result.status === 404 && result.message === 'no_data_available_for_export') {
					showNotifyWindow('show', 'error', i18n.t('errorMessages.no_record_found_to_export'));
					setShowLoadingBar(false); 
                } else {
                    throw Error(result.statusText);
                }
            })
            .catch(() => {
                showNotifyWindow('show', 'error', 'Unexpected Error');
                setShowLoadingBar(false);
            });
    };
    const onHandleClick = () => {
        setSearchValue('');
        setActivePage(1)
        setSearchValueClose(searchValueClose + 1)
        setInitialOrdering(true);
      }
    return (
        <React.Fragment>
            <Notify showNotify={showNotify} setShowNotify={setShowNotify} notifyDescription={notifyDescription} setNotifyType={setNotifyType} setNotifyDescription={setNotifyDescription} notifyType={notifyType} />
            <div className="col-md-12">
                <div className="box box-content-white">
                    <div className="row mb-1">
                        <div className="col">
                            <div className={'dataTables_filter'}>
                                {isAdminModule === 'true' ?
                                    <div className="link dictionaries-back pl-0" onClick={backToDictionaryPage}><BackArrowWithLabel label={i18n.t("dictionariesPages.dictionaries")} /></div>
                                    :
                                    <div className="link dictionaries-back pl-0" onClick={backToCodes}><BackArrowWithLabel label={i18n.t("codes.codes")}  /></div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="box-content">
                        <div className="common-search-wrapper-style">
                            <div className="input-content-box">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" name="searchValue" value={searchValue} onValueChange={onHandleChange} label={i18n.t("dictionariesPages.dictAdjustmentReasonCode.codeOrDescription")} onKeyDown={handleKeyDown} />
                                    {(searchValue.length !== 0) && <IconButton
										className="templateIconBtn_close"
										onClick={onHandleClick} >
										<CloseIcon sx={{ fontSize: "small", color: '#9093a4' }} />
									</IconButton>
									}
                                </div>
                            </div>
                            <div className="input-content-box padding-top15">
                                <div className="padding-top17">
                                    <button type="button" onClick={(e) => onSearchAdjustmentReasonCode(e)} className="btn btn-primary-blue margin-top10">{i18n.t("dictionariesPages.dictAdjustmentReasonCode.search")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="box box-content-white margin-top10">
                    <div className="">
                        <div className="table-responsive">
                            {isAdminModule !== 'true' &&
                                <>
                                    <div className="alignRight margin-bottom10 margin-top10">
                                        <button type="button" data-testid='adjustment-practice-add' className="btn btn-primary-blue" onClick={addNewToPractice}>{i18n.t("buttons.addNew")}</button>
                                    </div>
                                    <div className="alignRight margin-bottom10 margin-top10 mr-4">
                                        <ExportIconButton
                                            disabled={!adjustmentReasonCodeList?.length }
                                            onExportCsv={(e) => OnExportMenuItemClicked(e, 'csv')}
                                            onExportXls={(e) => OnExportMenuItemClicked(e, 'xls')} />
                                    </div>
                                </>
                            }
                            {isAdminModule === 'true' &&
                                <div className="alignRight">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="download-tooltip" style={{
                                            opacity: 0.4,
                                        }}>{i18n.t('commons.importCSV')}
                                        </Tooltip>}
                                    >
                                        <CommonButton icon="download" variant="outlined" label="Import" onClick={(e) => onHandleCsvImport(e)}  /> 
                                    </OverlayTrigger>
                                    <input name="csvFile" type="file" accept=".csv" id="file" onChange={onHandleCSVChange} style={{ display: "none" }} />
                                        <div className="alignRight margin-left15">
                                            <CommonButton  variant="contained" onClick={addNew} label={i18n.t("buttons.addNew")} />
                                        </div>
                                </div>
                            }
                            <div className="">          
                                <div className="padding-top10  margin-bottom10" style={{ overflowX: "auto", width: "100%", maxWidth: (innerWidth - 300) + "px" }} >
                                    <Table tableObject={(isAdminModule === 'true') ? AdjustmentReasonCodeTableAdminObject : AdjustmentReasonCodeTableObject} list={masterReasonCodeList} dropDownFunction={dropDownFunction} sortingFunction={tableSorting} />
                                </div>
                                <Pagination totalPage={totalPage} activePage={activePage} startIndex={startIndex} endIndex={endIndex} onPagePrevious={onPagePrevious} onPageUp={onPageUp} onPageNext={onPageNext} />
                                <div className="clear-b-scroll-sector">&nbsp;</div>
                                <div className="clear-b-scroll-sector">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomizedDialogs showModal={showModalWindow} type="save" header={header} setShowModalWindow={setShowModalWindow} resetForm={resetForm} onHide={onHide}>
                <Form id="form_dataEntry" autoComplete="off" onSubmit={(e) => onSaveFormData(e, 'master')} encType="multipart/form-data">
                    <Form.Group>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group padding-top15">
                                    <TextInput type="text" id="adjustmentReasonCode" name="adjustmentReasonCode" required={true} label={i18n.t('dictionariesPages.dictAdjustmentReasonCode.lblAdjustmentReasonCode')} onValueChange={onHandleChange} className={form_error.adjustmentReasonCode ? 'input-error' : ""} value={adjustmentReasonCode} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor='desc-text-area'>{i18n.t('dictionariesPages.dictAdjustmentReasonCode.lblDescription')}</label>
                                    <div className={'text-input'}>
                                        <textarea id='desc-text-area' placeholder={i18n.t('dictionariesPages.dictAdjustmentReasonCode.lblDescription')} name="description" required={true} className={!description ? 'textarea-100 input-error' : "textarea-100"} value={description} onChange={(e) => onHandleChange(e)}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </CustomizedDialogs>
            <CustomizedSmallDialogs showModal={showDeleteModalWindow} header={i18n.t("commons.confirmDelete")} type="delete" deleteItem={onDeleteAction} resetForm={resetForm} onHide={onDeleteHide} setShowModalWindow={setShowDeleteModalWindow}>
                {i18n.t("dictionariesPages.dictAdjustmentReasonCode.deletConfirmation") + "'" + deleteAdjustmentReasonCode + "'?"}
            </CustomizedSmallDialogs>
            <CustomizedDialogs
                showModal={showMasterReasonsModal}
                onSaveFormData={onSaveFormData}
                header={i18n.t("dictionariesPages.dictAdjustmentReasonCode.addHeader")}
                type={practicePK && selectedIds.length ? "formSubmit" : ""} // Set the type as "formSubmit" only if practice module is true and adjustments codes are selected, otherwise, set it as an empty string to hide the save button in the modal.
                resetForm={resetMatserReasonsData}
                setShowModalWindow={setShowMasterReasonsModal}
            >
                <div className="row">
                    <div className="col-6">
                        <div className="form-group padding-top20">
                            <TextInput name="searchReasonCode" value={searchReasonCode} onValueChange={onHandleChange} label={i18n.t("dictionariesPages.dictAdjustmentReasonCode.lblAdjustmentReasonCode")} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Table tableObject={ARCAddTableObject} onClickGridCheckBox={onClickGridCheckBox} />
                        <Pagination totalPage={masterTotalPage} activePage={masterActivePage} startIndex={masterStartIndex} endIndex={masterEndIndex} onPagePrevious={onMasterPagePrevious} onPageUp={onMasterPageUp} onPageNext={onMasterPageNext} />
                        {masterTotalPage <= 1 && <div className="clear-b-scroll-sector">&nbsp;</div>}
                    </div>
                </div>
            </CustomizedDialogs>
        </React.Fragment>
    )
}

export default AdjustmentReasonCode